@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .custom-scrollbar {
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
    .custom-scrollbar::-webkit-scrollbar {
      display: none;
    }
  }